import React, { useState } from 'react';
import axios from 'axios';
import { Space, Modal } from 'antd';
import { useAuth } from "../authContext";
import styles from "../styles/Commands.module.css"
import sharedStyles from "../styles/Common.module.css"
import { FaChevronRight } from "react-icons/fa";


export default function ComandsComponent(props) {

   
    const [message, setMessage] = useState('')
    const [newDeviceId, setNewDeviceId] = useState('')

    const [toggleResetModal, setToggleResetModal] = useState(false);
    const [toggleShutdownModal, setToggleShutdownModal] = useState(false);
    const [toggleFindModal, setToggleFindModal] = useState(false);
    const [toggleFactoryModal, setToggleFactoryModal] = useState(false);
    const [toggleChargeSettingsModal, setToggleChargeSettingsModal] = useState(false);
    const [toggleChargeContactsModal, setToggleChargeContactsModal] = useState(false);

    const [toggleSwapModal, setToggleSwapModal] = useState(false);
  
    const { tokens } = useAuth();
    

    const {
        openMessageApi,
        imei,
        leftIcon,
        leftIconWidth,
        leftIconHeight,
        title,
        subtitle,
        resetWatch,
        watchInfo,
        hardwareModel
    } = props

    let { deviceId = '' } = props


    //Reset
    const showResetModal = () => {
        setToggleResetModal(true)
    }

    const handleResetOk = () => {
        resetWatch(deviceId, imei);
        setToggleResetModal(false)
    }

    const handleResetCancel = () => {
        setToggleResetModal(false)
    }

    //Shutdown
    const showShutdownModal = () => {
        setToggleShutdownModal(true)
    }

    const handleShutdownOk = () => {
        shutDown()
        setToggleShutdownModal(false)
    }

    const handleShutdownCancel = () => {
        setToggleShutdownModal(false)
    }

    //Find
    const showFindModal = () => {
        setToggleFindModal(true)
    }

    const handleFindOk = () => {
        find()
        setToggleFindModal(false)
    }

    const handleFindCancel = () => {
        setToggleFindModal(false)
    }
    //Factory
    const showFactoryModal = () => {
        setToggleFactoryModal(true)
    }

    const handleFactoryOk = () => {
        factory()
        setToggleFactoryModal(false)
    }

    const handleFactoryCancel = () => {
        setToggleFactoryModal(false)
}
    //ChargeSettings
    const showChargeSettingsModal = () => {
        setToggleChargeSettingsModal(true)
    }

    const handleChargeSettingsOk = () => {
        chargeSettings()
        setToggleChargeSettingsModal(false)
    }

    const handleChargeSettingsCancel = () => {
        setToggleChargeSettingsModal(false)
    }
    //ChargeContacts
       const showChargeContactsModal = () => {
        setToggleChargeContactsModal(true)
    }

    const handleChargeContactsOk = () => {
        chargeContacts()
        setToggleChargeContactsModal(false)
    }

    const handleChargeContactsCancel = () => {
        setToggleChargeContactsModal(false)
    }
        //Change
    const handleChange = (event) => {
        setMessage(event.target.value);
    }

    const handleDeviceIdChange = (event) => {
        setNewDeviceId(event.target.value);
    }
 
    async function onSendMessage() {
        // console.log('success');
        // return;
        // setSendLoading(true);
        if (!deviceId && imei) {
            deviceId = imei.slice(4, 14);
        }
        if (message !== "") {
            await axios.post(process.env.REACT_APP_BACKEND_HOST + '/wearer/sendMessageToWearer', { message, deviceId }, { headers: { Authorization: `Bearer ${tokens.AccessToken}` } });
        }
        // setSendLoading(false);
    }

    async function shutDown() {
        openMessageApi('Loading...', 'loading')
        try {
            if (!deviceId && imei) {
                deviceId = imei.slice(4, 14);
            }
            const response = await axios.post(process.env.REACT_APP_BACKEND_HOST + '/wearer/powerOff', { deviceId }, { headers: { Authorization: `Bearer ${tokens.AccessToken}` } });

            console.log(response);
            if (response.status === 200) {
                openMessageApi('Success!', 'success')
            } else {
                openMessageApi(`Error ${response.status}: ${response.data.message}`, 'error')
            }
        } catch (error) {
            openMessageApi(`Error: ${error.message}`, 'error')
        }
    }

    async function find() {
        openMessageApi('Loading...', 'loading')
        try {
            if (!deviceId && imei) {
                deviceId = imei.slice(4, 14);
            }
            const response = await axios.post(process.env.REACT_APP_BACKEND_HOST + '/wearer/find', { deviceId }, { headers: { Authorization: `Bearer ${tokens.AccessToken}` } });

            console.log(response);
            if (response.status === 200) {
                openMessageApi('Success!', 'success')
            } else {
                openMessageApi(`Error ${response.status}: ${response.data.message}`, 'error')
            }
        } catch (error) {
            openMessageApi(`Error: ${error.message}`, 'error')
        }
    }

    async function factory() {
        openMessageApi('Loading...', 'loading')
        try {
            if (!deviceId && imei) {
                deviceId = imei.slice(4, 14);
            }
            const response = await axios.post(process.env.REACT_APP_BACKEND_HOST + '/wearer/factory', { deviceId }, { headers: { Authorization: `Bearer ${tokens.AccessToken}` } });

            console.log(response);
            if (response.status === 200) {
                openMessageApi('Success!', 'success')
            } else {
                openMessageApi(`Error ${response.status}: ${response.data.message}`, 'error')
            }
        } catch (error) {
            openMessageApi(`Error: ${error.message}`, 'error')
        }
    }

    async function chargeSettings() {
        openMessageApi('Loading...', 'loading')
        try {
            if (!deviceId && imei) {
                deviceId = imei.slice(4, 14);
            }
            const response = await axios.post(process.env.REACT_APP_BACKEND_HOST + '/wearer/chargeSettings', { deviceId }, { headers: { Authorization: `Bearer ${tokens.AccessToken}` } });

            console.log(response);
            if (response.status === 200) {
                openMessageApi('Success!', 'success')
            } else {
                openMessageApi(`Error ${response.status}: ${response.data.message}`, 'error')
            }
        } catch (error) {
            openMessageApi(`Error: ${error.message}`, 'error')
        }
    }

    async function chargeContacts() {
        openMessageApi('Loading...', 'loading')
        try {
            if (!deviceId && imei) {
                deviceId = imei.slice(4, 14);
            }
            const response = await axios.post(process.env.REACT_APP_BACKEND_HOST + '/wearer/chargeContacts', { deviceId }, { headers: { Authorization: `Bearer ${tokens.AccessToken}` } });

            console.log(response);
            if (response.status === 200) {
                openMessageApi('Success!', 'success')
            } else {
                openMessageApi(`Error ${response.status}: ${response.data.message}`, 'error')
            }
        } catch (error) {
            openMessageApi(`Error: ${error.message}`, 'error')
        }
    }

    async function onSwapWearers() {
        if (!deviceId && imei) {
            deviceId = imei.slice(4, 14);
        }
        if (deviceId.length !== 10) {
            openMessageApi('Error al obtener el deviceId actual', 'error')
            return;
        }
        if (newDeviceId.length !== 10) {
            openMessageApi('El nuevo deviceId debe tener 10 números', 'error')
            return;
        } else if (newDeviceId === deviceId) {
            openMessageApi('El nuevo deviceId debe ser diferente al actual', 'error')
            return;
        } else if (isNaN(newDeviceId)) {
            openMessageApi('El nuevo deviceId debe ser numérico', 'error')
            return;
        }
        setToggleSwapModal(true)
    }

    async function handleSwapOk() {
        try {
            await axios.put(process.env.REACT_APP_BACKEND_HOST + '/wearer/swapWearers', { newDeviceId, deviceId }, { headers: { Authorization: `Bearer ${tokens.AccessToken}` } });
            openMessageApi('Success!', 'success')
        } catch (error) {
            openMessageApi(`Error: ${error.message}`, 'error')
        }
        setToggleSwapModal(false)
    }

    async function handleSwapCancel() {
        setToggleSwapModal(false)
    }

    if (hardwareModel === "Soymomo_Space_v3") {

        return (

            <div className={sharedStyles.generalCard}>
                <div className={sharedStyles.cardSubContainer}>
                    <div className={sharedStyles.flexCenter}>
                        <div className={sharedStyles.iconContainer}>
                            <img src={leftIcon} width={leftIconWidth} height={leftIconHeight} alt='SoyMomo Logo' />
                        </div>
                        <div className={sharedStyles.flexAndCol}>
                            <h1 className={sharedStyles.iconTitle}>{title}</h1>
                            <p className={sharedStyles.iconSubTitle}>{subtitle}</p>
                        </div>
                    </div>
                </div>
                <div className={sharedStyles.metaData}>
    
                    <h3 className={styles.comandTitle2}><strong>Transferir reloj</strong></h3>
                    <Space.Compact className={styles.inputContainer}>
                        {/* eslint-disable-next-line react/no-unknown-property */}
                        <input placeholder="Ingrese el deviceId del nuevo dispositivo" onChange={handleDeviceIdChange} value={newDeviceId} onKeyDown={(e) => e.key === 'Enter' && onSwapWearers()} className={styles.textBox} />
                        <div className={styles.space} />
                        <div className={styles.sendIcon}><FaChevronRight onClick={onSwapWearers} /></div>
                    </Space.Compact>
    
                    <Modal
                        title="Transferir Reloj"
                        open={toggleSwapModal}
                        onOk={handleSwapOk}
                        onCancel={handleSwapCancel}
                        okButtonProps={{ className: styles.okBtn }}
                        cancelButtonProps={{ className: styles.cancelBtn }}
                        className="my-custom-modal-class"
                    >
                        Estás seguro/a que quieres transferir el reloj a otro dispotivo nuevo?
                        Desde: {deviceId} Hacia: {newDeviceId}
                    </Modal>
    
                    <h3 className={styles.comandTitle2}><strong>Enviar mensaje</strong></h3>
                    <Space.Compact className={styles.inputContainer}>
                        {/* eslint-disable-next-line react/no-unknown-property */}
                        <input placeholder="Ingrese mensaje a enviar" onChange={handleChange} value={message} onKeyDown={(e) => e.key === 'Enter' && onSendMessage()} className={styles.textBox} />
                        <div className={styles.space} />
                        <div className={styles.sendIcon}><FaChevronRight onClick={onSendMessage} /></div>
                    </Space.Compact>
    
                    {/* <Search loading={sendLoading} placeholder="Ingrese mensaje a enviar" onSearch={onSendMessage} enterButton="Enviar" className='bg-[#603BB0] hover:bg-[#3CB5C7] rounded-lg'/> */}
                    <div className={styles.btnContainerTwo}>
                    <div className={styles.btnContainerChildTwo}>
                        <button onClick={showFindModal} className={styles.regularBtn}><strong>Sonar</strong></button>
                        <button onClick={showShutdownModal} className={styles.shutDownBtn}><strong>Apagar</strong></button>
                        <button onClick={showResetModal} className={styles.shutDownBtn}><strong>Resetear</strong></button>
                    </div>
                    </div>
                    <Modal
                        title="Resetear Reloj"
                        open={toggleResetModal}
                        onOk={handleResetOk}
                        onCancel={handleResetCancel}
                        okButtonProps={{ className: styles.okBtn }}
                        cancelButtonProps={{ className: styles.cancelBtn }}
                        className="my-custom-modal-class"
                    >
                        ¿Estás seguro/a que quieres resetear el reloj a sus configuraciones de fábrica?
                        Se borrarán los siguientes datos y más:
                        <ul>
                            <li>Usuarios vinculados: {watchInfo.users}</li>
                            <li>Historial de mensajes: {watchInfo.userMessages + watchInfo.friendMessages} </li>
                            <li>Amigos: {watchInfo.friends} </li>
                            <li>Contactos: {watchInfo.contacts} </li>
                        </ul>
                    </Modal>
                    <Modal
                        title="Apagar Reloj"
                        open={toggleShutdownModal}
                        onOk={handleShutdownOk}
                        onCancel={handleShutdownCancel}
                        okButtonProps={{ className: styles.okBtn }}
                        cancelButtonProps={{ className: styles.cancelBtn }}
                        className="my-custom-modal-class"
                    >
                        ¿Estás seguro/a que quieres apagar el reloj?
                    </Modal>
                    <Modal
                        title="Sonar"
                        open={toggleFindModal}
                        onOk={handleFindOk}
                        onCancel={handleFindCancel}
                        okButtonProps={{ className: styles.okBtn }}
                        cancelButtonProps={{ className: styles.cancelBtn }}
                        className="my-custom-modal-class"
                    >
                        ¿Estás seguro/a que quieres hacer sonar el reloj?
                    </Modal>
                </div>
            </div>
    )

    }
//hardwareModel === "Soymomo_Space_v2" ? "Space 2.0":
//hardwareModel === "Soymomo_Space_v1" ? "Space 1.0":
//hardwareModel === "Soymomo_Space_Lite_v1" ? "Space Lite":
    else {
    return (

        <div className={sharedStyles.generalCard}>
            <div className={sharedStyles.cardSubContainer}>
                <div className={sharedStyles.flexCenter}>
                    <div className={sharedStyles.iconContainer}>
                        <img src={leftIcon} width={leftIconWidth} height={leftIconHeight} alt='SoyMomo Logo' />
                    </div>
                    <div className={sharedStyles.flexAndCol}>
                        <h1 className={sharedStyles.iconTitle}>{title}</h1>
                        <p className={sharedStyles.iconSubTitle}>{subtitle}</p>
                    </div>
                </div>
            </div>
            <div className={sharedStyles.metaData}>

                <h3 className={styles.comandTitle2}><strong>Transferir reloj</strong></h3>
                <Space.Compact className={styles.inputContainer}>
                    {/* eslint-disable-next-line react/no-unknown-property */}
                    <input placeholder="Ingrese el deviceId del nuevo dispositivo" onChange={handleDeviceIdChange} value={newDeviceId} onKeyDown={(e) => e.key === 'Enter' && onSwapWearers()} className={styles.textBox} />
                    <div className={styles.space} />
                    <div className={styles.sendIcon}><FaChevronRight onClick={onSwapWearers} /></div>
                </Space.Compact>

                <Modal
                    title="Transferir Reloj"
                    open={toggleSwapModal}
                    onOk={handleSwapOk}
                    onCancel={handleSwapCancel}
                    okButtonProps={{ className: styles.okBtn }}
                    cancelButtonProps={{ className: styles.cancelBtn }}
                    className="my-custom-modal-class"
                >
                    Estás seguro/a que quieres transferir el reloj a otro dispotivo nuevo?
                    Desde: {deviceId} Hacia: {newDeviceId}
                </Modal>

                <h3 className={styles.comandTitle2}><strong>Enviar mensaje</strong></h3>
                <Space.Compact className={styles.inputContainer}>
                    {/* eslint-disable-next-line react/no-unknown-property */}
                    <input placeholder="Ingrese mensaje a enviar" onChange={handleChange} value={message} onKeyDown={(e) => e.key === 'Enter' && onSendMessage()} className={styles.textBox} />
                    <div className={styles.space} />
                    <div className={styles.sendIcon}><FaChevronRight onClick={onSendMessage} /></div>
                </Space.Compact>

                {/* <Search loading={sendLoading} placeholder="Ingrese mensaje a enviar" onSearch={onSendMessage} enterButton="Enviar" className='bg-[#603BB0] hover:bg-[#3CB5C7] rounded-lg'/> */}
                <div className={styles.btnContainer}>
                <div className={styles.btnContainerChild}>
                    <button onClick={showFindModal} className={styles.regularBtn}><strong>Sonar</strong></button>
                    <button onClick={showChargeContactsModal} className={styles.regularBtn}><strong>Cargar Contactos</strong></button>
                    <button onClick={showChargeSettingsModal} className={styles.regularBtn}><strong>Cargar Config.</strong></button>
                </div>
                <div className={styles.btnContainerChild}>
                    <button onClick={showFactoryModal} className={styles.factoryBtn}><strong>Factory</strong></button>
                    <button onClick={showShutdownModal} className={styles.shutDownBtn}><strong>Apagar</strong></button>
                    <button onClick={showResetModal} className={styles.shutDownBtn}><strong>Resetear</strong></button>
                </div>
                </div>
                <Modal
                    title="Resetear Reloj"
                    open={toggleResetModal}
                    onOk={handleResetOk}
                    onCancel={handleResetCancel}
                    okButtonProps={{ className: styles.okBtn }}
                    cancelButtonProps={{ className: styles.cancelBtn }}
                    className="my-custom-modal-class"
                >
                    ¿Estás seguro/a que quieres resetear el reloj a sus configuraciones de fábrica?
                    Se borrarán los siguientes datos y más:
                    <ul>
                        <li>Usuarios vinculados: {watchInfo.users}</li>
                        <li>Historial de mensajes: {watchInfo.userMessages + watchInfo.friendMessages} </li>
                        <li>Amigos: {watchInfo.friends} </li>
                        <li>Contactos: {watchInfo.contacts} </li>
                    </ul>
                </Modal>
                <Modal
                    title="Apagar Reloj"
                    open={toggleShutdownModal}
                    onOk={handleShutdownOk}
                    onCancel={handleShutdownCancel}
                    okButtonProps={{ className: styles.okBtn }}
                    cancelButtonProps={{ className: styles.cancelBtn }}
                    className="my-custom-modal-class"
                >
                    ¿Estás seguro/a que quieres apagar el reloj?
                </Modal>
                <Modal
                    title="Sonar"
                    open={toggleFindModal}
                    onOk={handleFindOk}
                    onCancel={handleFindCancel}
                    okButtonProps={{ className: styles.okBtn }}
                    cancelButtonProps={{ className: styles.cancelBtn }}
                    className="my-custom-modal-class"
                >
                    ¿Estás seguro/a que quieres hacer sonar el reloj?
                </Modal>
                <Modal
                    title="Factory"
                    open={toggleFactoryModal}
                    onOk={handleFactoryOk}
                    onCancel={handleFactoryCancel}
                    okButtonProps={{ className: styles.okBtn }}
                    cancelButtonProps={{ className: styles.cancelBtn }}
                    className="my-custom-modal-class"
                >
                    ¿Estás seguro/a que quieres hacer un factory al reloj?
                </Modal>
                <Modal
                    title="Cargar configuración"
                    open={toggleChargeSettingsModal}
                    onOk={handleChargeSettingsOk}
                    onCancel={handleChargeSettingsCancel}
                    okButtonProps={{ className: styles.okBtn }}
                    cancelButtonProps={{ className: styles.cancelBtn }}
                    className="my-custom-modal-class"
                >
                    ¿Estás seguro/a que quieres hacer un cargar configuración al reloj?
                </Modal>
                <Modal
                    title="Cargar contactos"
                    open={toggleChargeContactsModal}
                    onOk={handleChargeContactsOk}
                    onCancel={handleChargeContactsCancel}
                    okButtonProps={{ className: styles.okBtn }}
                    cancelButtonProps={{ className: styles.cancelBtn }}
                    className="my-custom-modal-class"
                >
                    ¿Estás seguro/a que quieres hacer un cargar contactos al reloj?
                </Modal>
            </div>
        </div>

    )
}
}