import React, { useEffect, useState } from 'react';

const DefaultTabletPin = () => {
    const [pin, setPin] = useState('');

    useEffect(() => {
        // Function to get the default PIN
        const getDefaultTabletPin = async () => {
            const currentDate = new Date().toISOString().split('T')[0];
            const hashBuffer = new TextEncoder().encode(currentDate);
            const hash = await crypto.subtle.digest('SHA-256', hashBuffer);
            const hashArray = Array.from(new Uint8Array(hash));
            const hashString = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
            const pin = hashString.replace(/\D/g, '').substring(0, 4).padEnd(4, '0');
            setPin(pin);
        };

        getDefaultTabletPin();
    }, []);

    return (
        <div style={{fontWeight: "bold"}}>
            <h2>Daily Default PIN</h2>
            <p>{pin}</p>
        </div>
    );
};

export default DefaultTabletPin;