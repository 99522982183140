import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import styles from '../styles/WearerMainCard.module.css';


export default function WearerMainCard(props) {
  const wearer = props.wearer || {};

  const {
    firstName = '',
    lastName = '',
    phone = '',
    imei = '',
    hardwareModel = '',
  } = wearer;


  return (
    <div className={styles.generalContainer}>
        <div className={styles.leftContainer}>
            <img src="/images/cs-SoyMomoLogoRound.svg" alt="SoyMomo Icon" />
            <div className={styles.textContainer}>
                <h1 className={styles.name}>{firstName} {lastName}</h1>
                <p className={styles.hardwareDesc}>{phone}</p>
                <p className={styles.hardwareDesc}>Imei: {imei}</p>
                <p className={styles.hardwareDesc}>{
                hardwareModel? (
                  hardwareModel === "Soymomo_Space_v3" ? "Space 3.0":
                    hardwareModel === "Soymomo_Space_v2" ? "Space 2.0":
                      hardwareModel === "Soymomo_Space_v1" ? "Space 1.0":
                        hardwareModel === "Soymomo_Space_Lite_v1" ? "Space Lite": "Sin info del modelo"
                  ) : "Sin info del modelo"
                  }</p>
            </div>
        </div>
        <img src="/images/cs-defaultWatchModelShadow.svg" alt="SoyMomo default watch model" className={styles.image} />
        <button className={styles.editButton} onClick={props.openEditModal}>
          <FontAwesomeIcon icon={faEdit} />
        </button>
    </div>
  );
}
